export const seo = {
  url: 'client/faq',
  title: {
    pl: 'FAQ - pytania & odpowiedzi',
    en: 'FAQ - questions & answers',
  },
  desc: {
    pl: `Zapoznaj się z najczęściej zadawanymi pytaniami z zakresu TSL. `,
    en: `The most frequently asked questions in the field of TSL.`,
  },
  keywords: ['omida', 'sea and air', 'sea & air', 'pytania', 'odpowiedzi'],
}

export const intro = {
  title: {
    pl: 'FAQ',
    en: 'FAQ',
  },
  desc: {
    pl: `Zapoznaj się z najczęściej zadawanymi pytaniami z zakresu TSL. `,
    en: `The most frequently asked questions in the field of TSL.`,
  },
}

export const faq = [
  {
    q: 'Kim jest spedytor?',
    a: 'Spedytor jest to osoba, która zawodowo i za wynagrodzeniem, w imieniu własnym lub zleceniodawcy albo na jego rachunek, podejmuje się organizacji wysłania i/lub odbioru przesyłki oraz innych czynności wymaganych i związanych z dostarczeniem przesyłki. O zakresie usługi decyduje treść umowy spedycyjnej oraz specyfika ładunku.',
  },
  {
    q: 'Co to jest „gestia transportowa”?',
    a: 'Gestia transportowa to zastrzeżone w kontrakcie transportowym prawo i obowiązek zorganizowania transportu i pokrycia jego kosztów. Najważniejszymi zaletami posiadania gestii transportowej jest bezpośredni wpływ strony odpowiedzialnej za transport na przebieg procesu transportowego, w tym na rodzaj i termin realizacji przewozu oraz zmniejszenie kosztów przewozu towaru poprzez zorganizowanie transportu we własnym zakresie, jeśli transport w innej formie okaże się nieopłacalny.',
  },
  {
    q: 'Co to są warunki dostawy?',
    a: "Warunki dostawy określają reguły dostawy towaru od sprzedawcy do odbiorcy w przypadku handlu międzynarodowego. Precyzują kto i od którego momentu ponosi koszty i ryzyko związane z dostarczeniem i ubezpieczeniem przesyłki. Do najczęściej używanych warunków dostawy należą Incoterms 1990, 2000, 2010, Combiterms oraz Znowelizowane Amerykańskie Definicje w Handlu Zagranicznym, jednakże najczęściej używanymi w praktyce obrotów międzynarodowych są wykładnie <strong><a href='/pdf/incoterms-2020_pl.pdf'>Incoterms</a></strong>.",
  },
  {
    q: 'Czym jest konosament (Bill of Lading)?',
    a: 'Konosament (Bill of Lading) skrót B/L – to morski list przewozowy, dokument potwierdzający odbiór określonego ładunku na statek. Posiadacz tego dokumentu może w porcie docelowym odebrać przesyłany ładunek. Konosament jest papierem wartościowym, na podstawie którego można przenieść prawa własności towaru, jest dowodem zawarcia umowy o przewóz i odgrywa główną rolę w handlu międzynarodowym. Konosament jako papier wartościowy reprezentuje towar, na który go wystawiono i stanowi prawo do towaru, a w szczególności prawo dysponowania nim. Jest przedmiotem transakcji handlowych, jest sprzedawany i kupowany.',
  },
  {
    q: 'Co to znaczy „indosować” konosament?',
    a: 'Indosowanie (żyrowanie) konosamentu to po prostu złożenie oficjalnego podpisu na odwrocie konosamentu. Poprzez tzw. indos, wszelkie prawa wynikające z konosamentu na zlecenie zostają przeniesione na inną osobę wskazaną przez indosującego konosament.',
  },
  {
    q: 'Co to jest akredytywa dokumentowa (letter of credit, L/C)?',
    a: 'Akredytywa dokumentowa to uwarunkowana forma rozliczenia transakcji handlowej w obrocie międzynarodowym, w której bank importera zobowiązuje się wobec eksportera do zapłaty określonej kwoty w zamian za dokumenty zaprezentowane przez niego po wysyłce towaru. Bank dokonuje wypłaty z akredytywy tylko wówczas, gdy te dokumenty są ściśle zgodne z warunkami zapisanymi w akredytywie.',
  },
  {
    q: 'Co to jest międzynarodowy transport multimodalny?',
    a: 'Termin ten oznacza przewóz towarów przy użyciu co najmniej dwóch różnych gałęzi transportu, na podstawie jednej umowy o przewóz multimodalny z miejsca położonego w jednym kraju, gdzie towar przejął w pieczę operator transportu multimodalnego, do oznaczonego miejsca przeznaczenia położonego w innym kraju.',
  },
  {
    q: 'Co oznacza sformułowanie: door-to-door?',
    a: 'Door-to-door oznacza pełną obsługę spedycyjną, od momentu odbioru towaru od dostawcy, aż do momentu jego dostarczenia do odbiorcy, z uwzględnieniem wszelkich niezbędnych formalności.',
  },
  {
    q: 'Co oznacza skrót ETD?',
    a: 'ETD – estimated time of departure – przypuszczalny czas wypłynięcia statku z portu.',
  },
  {
    q: 'Co oznacza skrót ETA?',
    a: 'ETA – estimated time of arrival – przypuszczalny czas gotowości statku do wejścia do portu.',
  },
  {
    q: 'Co to jest feeder?',
    a: 'Feeder to statek, dowożący mniejsze partie kontenerów do/z portów oceanicznych z/do mniejszych portów np. z Gdańska do Hamburga, czy z Hamburga do Gdańska.',
  },
  {
    q: 'Co oznacza czas wolny od demurrage i detention?',
    a: 'Czas wolny od opłat demurrage i detention jest to czas określony przez armatora na podjęcie pełnego i zdanie pustego kontenera do portu lub na depot. Po upływie okresu wolnego naliczane są dodatkowe opłaty. Opłaty z tytułu demurrage obejmują okres po upływie czasu wolnego na podjęcie kontenera z portu. Opłaty z tytułu detention obejmują okres od momentu podjęcia do zdania pustego kontenera do portu lub na wskazany depot. Długość okresu wolnego od demurrage i detention może być różna. Wynika ona z zapisu w taryfach armatorskich lub też z indywidualnych warunków umowy z armatorami.',
  },
  {
    q: 'Co oznacza czas wolny od opłat za składowe?',
    a: 'Czas wolny od opłat za składowe jest to czas określony przez port/terminal na podjęcie pełnego kontenera. Po upływie okresu wolnego naliczane są dodatkowe opłaty, zgodne z taryfą portową/terminalową.',
  },
  {
    q: 'Co oznacza skróty FCL?',
    a: 'FCL – Full Container Load – oznacza przesyłkę pełnokontenerową, w praktyce oznaczającą wykorzystanie kontenera jedynie dla pojedynczego odbiorcy.',
  },
  {
    q: 'Co oznacza skróty LCL?',
    a: 'LCL – Less than Container Load – oznacza przesyłkę drobnicową, wykorzystującą jedynie część przestrzeni ładunkowej kontenera. Zaletą tej formy przewozu jest fracht liczony wg miary bądź wagi towaru (jednostką jest metr sześcienny lub tona).',
  },
  {
    q: 'Jakie są wymiary wewnętrzne kontenerów 20DV?',
    a: 'Kontener 20-stopowy (20ft) – 5883mm x 2330 x 2355 (długość x szerokość x wysokość).',
  },
  {
    q: 'Jakie są wymiary wewnętrzne kontenerów 40DV?',
    a: 'Kontener 40-stopowy (40ft) – 12010mm x 2337 x 2371 (długość x szerokość x wysokość).',
  },
  {
    q: 'Ile europalet mieści się w kontenerze?',
    a: 'W kontenerze 20-stopowym – 11 europalet. W kontenerze 40-stopowym – 23 europalety.',
  },
  {
    q: 'Co to jest CBM?',
    a: '<div>CBM (Cubic Meter) / kubik / metr sześcienny. <br/> Jednostka stosowana do pomiaru objętości ładunków. <br/> Zwykle mierzy się ją następująco: długość x szerokość x wysokość.</div>',
  },
  {
    q: 'Co to jest stawka waga / miara?',
    a: 'W / M – waga / miara – 1 cbm / 1 tona – do obliczenia frachtu wybiera się wartość większą/liczbę ton lub metrów sześciennych i mnoży ją przez podaną stawkę; jeżeli obliczony fracht jest niższy od stawki minimalnej, należy przyjąć stawkę minimum.',
  },
  {
    q: 'Na czym polega konsolidacja przesyłek?',
    a: 'Konsolidacja polega na łączeniu kilku mniejszych przesyłek (między innymi od różnych dostawców dla różnych odbiorców), podążających w tym samym kierunku w celu transportu ich w jednym kontenerze. Przesyłki takie nazywane są również niepełnokontenerowymi lub drobnicą (LCL - Less than Container Load). W tym celu spedytor sporządza na każdą skonsolidowana przesyłkę oddzielny list przewozowy oraz dodatkowo jeden list zbiorczy z listą ładunkową. Na liście zbiorczym nadawcą jest spedytor konsolidujący przesyłki, a odbiorcą spedytor dekonsolidujący. Przewoźnik ma wgląd tylko w list zbiorczy.',
  },
  {
    q: 'Co to jest kod IMDG?',
    a: 'Kod IMDG (International Maritime Dangerous Goods Code) jest to międzynarodowy morski kod materiałów niebezpiecznych.',
  },
  {
    q: 'Co oznacza skrót ADR?',
    a: 'Skrót ADR pochodzi od the European Agreement concerning the International Carriage of Dangerous Goods by Road i oznacza umowę dotyczącą międzynarodowego przewozu drogowego materiałów niebezpiecznych. Umowa ta reguluje zasady i warunki przewozu tego typu ładunków w celu zapewnienia im maksymalnego bezpieczeństwa. Umowa ADR stosowana jest również w przewozach krajowych.',
  },
  {
    q: 'Jakie towary określamy mianem towarów niebezpiecznych?',
    a: 'Towary niebezpieczne to materiały i przedmioty, których przewóz na podstawie przepisów zawartych w załącznikach do umowy ADR – jest zabroniony bądź dopuszczony, ale tylko na zasadach podanych w tychże załącznikach.',
  },
  {
    q: 'Co to jest towar o znaczeniu strategicznym?',
    a: 'Jest to towar podwójnego zastosowania oraz uzbrojenie. Towary podwójnego zastosowania są to towary, włącznie z oprogramowaniem i technologią, które mogą być stosowane zarówno w celach cywilnych, jak i wojskowych, termin ten obejmuje także wszystkie towary w zastosowaniach niewybuchowych i w jakikolwiek sposób mogące być wykorzystanymi do wspomagania wytwarzania broni jądrowej lub innych urządzeń do wybuchu jądrowego. Uzbrojenie jest to broń, amunicja, materiały wybuchowe, wyroby, ich części i technologie, określone w wykazie wydanym na podstawie rozporządzenia Ministra ds. Gospodarki.',
  },
  {
    q: 'Co oznacza skrót EXW?',
    a: 'Obowiązkiem sprzedającego jest postawienie towaru do dyspozycji kupującego w oznaczonym miejscu wydania (magazyn, zakład produkcyjny itp.). Obowiązkiem kupującego jest przejęcie ryzyka od tego miejsca oraz poniesienie wszystkich kosztów, w tym kosztów załadunku towaru na środek transportu oraz odprawy, w tym celnej, w eksporcie.',
  },
  {
    q: 'Co oznacza skrót FCA?',
    a: 'Obowiązkiem sprzedającego jest dostarczenie towaru przewoźnikowi lub innej osobie, np. spedytorowi, wyznaczonemu przez kupującego, w zakładzie sprzedającego (wówczas ma obowiązek załadować towar na środek transportu) lub w innym miejscu (wówczas ma obowiązek dostarczyć towar, gotowy do wyładunku, na środku transportu), a także dokonanie formalności związanych z odprawą w eksporcie. Obowiązkiem kupującego jest przejęcie ryzyka oraz zawarcie umowy przewozu od wyznaczonego miejsca dostawy, a także poinformowanie sprzedającego o nazwie przewoźnika, terminie załadunku oraz rodzaju środka transportu.',
  },
  {
    q: 'Co oznacza skrót CPT?',
    a: 'Obowiązkiem sprzedającego jest dostarczenie towaru przewoźnikowi (od tego miejsca ryzyko ponosi kupujący) oraz zawarcie umowy przewozu do określonego miejsca przeznaczenia i opłacenie jego kosztów. Jeśli podczas tego przewozu wystąpią dodatkowe koszty i opłaty (poza tymi, które wraz z frachtem pokrył sprzedający, zawierając umowę przewozu), to ponosi je kupujący.',
  },
  {
    q: 'Co oznacza skrót CIP?',
    a: 'W porównaniu do formuły CPT zakres obowiązków sprzedającego jest rozszerzony o zawarcie umowy ubezpieczenia i pokrycie jego kosztów oraz przekazanie kupującemu dowodu ubezpieczenia, aby mógł on, w przypadku uszkodzenia czy utraty towaru, dochodzić roszczeń od ubezpieczyciela. Inne obowiązki stron są takie jak w CPT.',
  },
  {
    q: 'Co oznacza skrót DAP?',
    a: 'Obowiązkiem sprzedającego jest dostarczenie towaru i postawienie go do dyspozycji kupującego, na środku transportu, gotowego do wyładunku, w określonym miejscu przeznaczenia. Wyładunek oraz odprawa importowa należą do obowiązków kupującego.',
  },
  {
    q: 'Co oznacza skrót DPU?',
    a: 'Obowiązkiem sprzedającego jest dostarczenie towaru i postawienie go do dyspozycji kupującego, po wyładunku ze środka transportu, w określonym miejscu/punkcie przeznaczenia. Jest to jedyna reguła wymagająca od sprzedającego dokonania wyładunku towaru ze środka transportu w miejscu przeznaczenia. Dokonanie odprawy w imporcie należy do obowiązków kupującego.',
  },
  {
    q: 'Co oznacza skrót DDP?',
    a: 'Obowiązkiem sprzedającego jest dostarczenie towaru do określonego miejsca przeznaczenia oraz postawienie go do dyspozycji kupującego, po dokonaniu odprawy importowej, na środku transportu, gotowego do wyładunku. Obowiązkiem kupującego jest wyładunek towaru.',
  },
  {
    q: 'Co oznacza skrót FAS?',
    a: 'Obowiązkiem sprzedającego jest dostarczenie towaru wzdłuż burty statku w porcie załadunku i od tego miejsca ryzyko ponosi kupujący. Kupujący ma obowiązek zawrzeć umowę przewozu i poinformować sprzedającego o nazwie statku oraz punkcie załadunku i wybranej dacie dostawy do portu.',
  },
  {
    q: 'Co oznacza skrót FOB?',
    a: 'Obowiązkiem sprzedającego jest dostarczenie towaru na statek w porcie załadunku. Od tego miejsca ryzyko i koszty ponosi kupujący. Kupujący ma obowiązek zawrzeć umowę przewozu i poinformować sprzedającego o nazwie statku oraz miejscu załadunku i wybranej dacie dostawy do portu.',
  },
  {
    q: 'Co oznacza skrót CFR?',
    a: 'Obowiązkiem sprzedającego jest dostarczenie towaru na statek w porcie załadunku. Od tego miejsca ryzyko ponosi kupujący. Sprzedający ma obowiązek zawrzeć umowę przewozu i opłacić jego koszty do portu przeznaczenia. Jeśli podczas przewozu towaru z portu załadunku do portu przeznaczenia, wystąpią dodatkowe koszty i opłaty, poza tymi które wraz z frachtem opłacił sprzedający, przy zawieraniu umowy przewozu, to ponosi je kupujący.',
  },
  {
    q: 'Co oznacza skrót CIF?',
    a: 'W porównaniu do formuły CFR zakres obowiązków sprzedającego jest rozszerzony o zawarcie umowy ubezpieczenia i pokrycie jego kosztów oraz przekazanie kupującemu dowodu ubezpieczenia, aby mógł on, w przypadku uszkodzenia czy utraty towaru, dochodzić roszczeń od ubezpieczyciela. Inne obowiązki stron są takie jak w CFR.',
  },
]
